import { RouterTab } from '../models/router-tab';
import { DEFAULT_PAGE_SIZE } from './default-page-size';

export const simpleCertificatesRoutesTabs: RouterTab[] = [
    {
        path: `courses?page=0&size=${DEFAULT_PAGE_SIZE}`,
        title: 'Курсы',
    },
    {
        path: `coaches?page=0&size=${DEFAULT_PAGE_SIZE}`,
        title: 'Тренеры',
    },
    {
        path: `certificates?page=0&size=${DEFAULT_PAGE_SIZE}`,
        title: 'Сертификаты',
    },
];
