import React, { ReactNode } from 'react';
import Pagination, { PaginationProps } from '../Pagination/Pagination';
import { ColumnFixed } from '../ColumnFixed';

export interface ColumnsFixed {
    [key: string]: {
        columnSize: string | number;
        value: string | number;
    };
}

export type CellFixed = string | number;

export interface ExtendedCellFixed {
    value?: CellFixed;
    align?: 'start' | 'end' | 'center';
    additionalComponent?: ReactNode;
    additionalClasses?: string;
    handleClick?: () => void;
}

export interface RowFixed {
    [key: string]: ExtendedCellFixed | CellFixed | undefined | any[] | any;
}

export interface GridPropsFixed {
    columns: ColumnsFixed;
    rows: RowFixed[];
    pagination?: PaginationProps;
}

export const GridFixed = (props: GridPropsFixed) => {
    const { columns, rows, pagination } = props;

    return (
        <>
            <div className="gridFixed">
                <div className="grid-header">
                    {Object.values(columns).map((x, index) => (
                        <ColumnFixed
                            className="space-between"
                            key={`${x.value}-${index}`}
                            columnSize={`${x.columnSize}`}
                        >
                            {x.value}
                        </ColumnFixed>
                    ))}
                </div>
                {rows.map((row, rowIndex) => (
                    <div key={rowIndex} className="grid-row">
                        {Object.keys(columns).map((key, cellIndex) => (
                            <ColumnFixed
                                key={`${key}-${cellIndex}`}
                                className="space-between"
                                columnSize={`${columns[key].columnSize}`}
                            >
                                {getCell(row[key])}
                            </ColumnFixed>
                        ))}
                    </div>
                ))}
            </div>

            {pagination?.size && <Pagination {...pagination} />}
        </>
    );
};

const getCell = (cellValue: CellFixed | ExtendedCellFixed | undefined | any[] | any) => {
    if (cellValue && typeof cellValue === 'object') {
        const { additionalClasses, handleClick, value, additionalComponent, align } = cellValue as ExtendedCellFixed;
        const classes = `cell ${additionalClasses ? additionalClasses : ''} cell-${align || 'stretch'}`;

        return (
            <div className={classes} onClick={handleClick}>
                {value}
                {additionalComponent}
            </div>
        );
    }

    return <div className="cell">{cellValue}</div>;
};
