import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import CoursesPage from '../courses/CoursesPage';
import Error404 from 'pages/error-404/Error404';
import CoachesPage from '../coaches/CoachesPage';
import { RouterTabs } from '../../components/RouterTabs/RouterTabs';
import { RouterTab } from 'models/router-tab';
import SimpleCertificatePageRoute from '../simple-certificates-page/SimpleCertificatesPage';
import { simpleCertificatesRoutesTabs } from 'constants/simple-certificate-routes';
import { DEFAULT_PAGE_SIZE } from 'constants/default-page-size';

const SimpleCertificatesPage = () => {
    const { path } = useRouteMatch();

    const getTabsWithPaths = (tabs: RouterTab[]) =>
        tabs.map((tab) => ({
            ...tab,
            path: `${path}/${tab.path}`,
        }));

    return (
        <>
            <RouterTabs tabs={getTabsWithPaths(simpleCertificatesRoutesTabs)} />

            <Switch>
                <Route path={`${path}/courses`}>
                    <CoursesPage />
                </Route>
                <Route path={`${path}/coaches`}>
                    <CoachesPage />
                </Route>
                <Route path={`${path}/certificates`}>
                    <SimpleCertificatePageRoute />
                </Route>
                <Redirect exact from={path} to={`${path}/courses?page=0&size=${DEFAULT_PAGE_SIZE}`} />
                <Route path={`${path}/*`} component={Error404} />
            </Switch>
        </>
    );
};

export default SimpleCertificatesPage;
