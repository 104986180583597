import React, { InputHTMLAttributes } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form-new';

export const Switch = (
    props: InputHTMLAttributes<HTMLInputElement> & { reg?: UseFormRegisterReturn; isSmall?: boolean; label?: string }
) => {
    const { id, name, onChange, onClick, reg, defaultValue, defaultChecked, onBlur, isSmall, label, disabled } = props;

    return (
        <label className="switch__container">
            <div className={`switch ${isSmall ? 'switch__small' : ''}`}>
                <input
                    id={id}
                    type="checkbox"
                    name={name}
                    defaultValue={defaultValue}
                    defaultChecked={defaultChecked}
                    onChange={onChange}
                    onClick={onClick}
                    onBlur={onBlur}
                    {...reg}
                    disabled={disabled}
                />
                <span className="switch-slider" />
            </div>

            {label && <span className="switch-label">{label}</span>}
        </label>
    );
};
