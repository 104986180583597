import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'hooks';
import { ResponsePagination } from 'models/response-pagination';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Button,
    Checkbox,
    Column,
    Container,
    DatePricker,
    EmptyListWarning,
    GridFixed,
    Icon,
    Label,
    Loader,
    Row,
    Select,
    TextInput,
    Title,
} from 'components';
import moment from 'moment';
import { dateFormat, dateTimeFormat } from 'constants/date-format';
import { SimpleCertificateModel } from 'models/simple-certificates/simple-certificate.model';
import { AdminSimpleCertificateService } from 'services/admin-simple-sertificate.service';
import { useDebouncedEffect } from 'hooks/useDebouncedEffect/useDebouncedEffect';
import { DEFAULT_PAGE_SIZE } from 'constants/default-page-size';
import { useTranslation } from 'react-i18next';
import { StudyType, StudyTypesNames } from 'enums/study-type';
import { Controller, FieldValues, useForm } from 'react-hook-form-new';
import { LanguageSimple, LanguageSimpleNames } from 'enums/language-simple';
import SelectByEnum from 'components/SelectByEnum/SelectByEnum';

const sizeUrlParam = 'size';
const pageUrlParam = 'page';

const filtersParams: Record<string, string> = {
    language: '',
    year: '',
    startDate: '',
    finishDate: '',
    durationDays: '',
    studyType: '',
    quarter: '',
    isPartner: '',
    search: '',
};

interface IProps {
    studyCenterId?: number;
}

const SimpleCertificateList: FC<IProps> = ({ studyCenterId }) => {
    const [t] = useTranslation(['page-simple-certification']);
    const queryParams = useQuery();

    const { push, replace } = useHistory();
    const { pathname } = useLocation();

    const urlSize = +(queryParams.get(sizeUrlParam) as string);
    const urlPage = +(queryParams.get(pageUrlParam) as string);

    const urlParams = { ...filtersParams };

    Object.keys(filtersParams).map((key) => {
        const value = queryParams.get(key) as string;
        urlParams[key] = value;
    });

    const [loading, setLoading] = useState(false);
    const [certificates, setCertificates] = useState<ResponsePagination<SimpleCertificateModel>>({
        count: 0,
        items: [],
    });

    const getCertificates = () => {
        if (urlSize === 0) return;
        setLoading(true);
        AdminSimpleCertificateService.getCertificates({
            page: urlPage,
            size: urlSize,
            ...urlParams,
            studyCenterId,
        })
            .then((d) => {
                setCertificates(d.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getCertificates();
    }, [urlSize, urlPage, ...Object.keys(urlParams).map((m) => urlParams[m])]);

    const getExcel = () => {
        AdminSimpleCertificateService.getCertificatesXls({ ...urlParams, studyCenterId });
    };

    const deleteHandle = (id: string) => {
        if (window.confirm(t('are-you-sure'))) {
            setLoading(true);
            AdminSimpleCertificateService.delete(id)
                .then(() => getCertificates())
                .finally(() => setLoading(true));
        }
    };

    const setPageSize = (page: number, size: number) => {
        queryParams.set(pageUrlParam, `${page}`);
        queryParams.set(sizeUrlParam, `${size}`);
        replace({
            search: queryParams.toString(),
            pathname,
        });
    };

    useDebouncedEffect(
        () => {
            queryParams.set(pageUrlParam, `0`);
            queryParams.set(sizeUrlParam, `${DEFAULT_PAGE_SIZE}`);
            replace({
                search: queryParams.toString(),
                pathname,
            });
        },
        [...Object.keys(urlParams).map((m) => urlParams[m])],
        40
    );

    const methods = useForm<Record<string, string>>();
    const {
        register,
        formState: { isSubmitting },
        handleSubmit,
        control,
        getValues,
        setValue,
    } = methods;

    const getGridData = (): Row[] =>
        certificates.items.map((item) => ({
            ...item,
            creationDate: moment(item.creationDate).format(dateTimeFormat),
            updateActions: {
                additionalComponent: (
                    <Button pSize="none" handleClick={() => push(`${pathname}/edit/${item.id}`)}>
                        <Icon iconName="edit" />
                    </Button>
                ),
            },
            deleteActions: {
                additionalComponent: (
                    <Button pSize="none" handleClick={() => deleteHandle(item.id)}>
                        <Icon iconName="delete" />
                    </Button>
                ),
            },
            downloadActions: {
                additionalComponent: (
                    <a
                        className="link"
                        href={`/api/AdminSimpleCertificate/Download/${item.id}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('download')}
                    </a>
                ),
            },
            courseCodeAndName: item.courseStr,
            startDate: moment(item.startDate).format(dateFormat),
            finishDate: moment(item.finishDate).format(dateFormat),
            duration: item.durationDays,
            middleName: item.patronymic,
            coach: item.coaches?.map((m) => `${m.lastName} ${m.firstName}`).join(', '),
            studyType: StudyType[item.studyType],
            isPartner: {
                additionalComponent: (
                    <Checkbox isChecked={item?.isPartner ?? false} id={`canCreateCertificate-${item.id}`} isDisabled />
                ),
            },
            editDate: moment(item.editDate).format(dateTimeFormat),
        }));

    const search = (values: FieldValues) => {
        Object.keys(values).map((key) => {
            queryParams.set(key, values[key] === null || values[key] === undefined ? '' : values[key]);
        });
        replace({
            search: queryParams.toString(),
            pathname,
        });
    };
    const clear = () => {
        const formValues = getValues();
        Object.keys(filtersParams).map((key) => {
            queryParams.delete(key);
            Object.keys(formValues).map((formKey) => {
                if (formKey !== key) return;

                setValue(formKey, '');
            });
        });
        replace({
            search: queryParams.toString(),
            pathname,
        });
    };

    const arrPartner = [
        { label: t('all'), value: '' },
        { label: t('partner'), value: 'true' },
        { label: t('notPartner'), value: 'false' },
    ];

    const arrQuarter = [
        { label: t('all'), value: '' },
        { label: t('q1'), value: '1' },
        { label: t('q2'), value: '2' },
        { label: t('q3'), value: '3' },
        { label: t('q4'), value: '4' },
    ];

    return (
        <>
            {loading && <Loader isBlock />}
            <Container>
                <Column columnSize="50">
                    <Title titleText={t('generated-certificates')} />
                </Column>
                <Column columnSize="100" className="text-right">
                    <Button buttonClass="primary" handleClick={() => push(`${pathname}/add`)}>
                        {t('create')}
                    </Button>

                    {certificates && certificates.count !== 0 && (
                        <Button buttonClass="primary" handleClick={getExcel}>
                            Excel
                        </Button>
                    )}
                </Column>
                <form onSubmit={handleSubmit(search)} autoComplete="off" className="container top-space-10x">
                    <Column columnSize="30" className="p-right-space-3x">
                        <TextInput
                            label={t('search')}
                            id="search"
                            defaultValue={urlParams['search']}
                            reg={register('search')}
                        />
                    </Column>
                    <Column columnSize="30" className="p-right-space-3x top-space-3x">
                        <Controller
                            control={control}
                            name="language"
                            defaultValue={urlParams['language']}
                            render={({ field: { onChange } }) => (
                                <SelectByEnum
                                    label={t('language')}
                                    onChange={(id) => onChange(id)}
                                    enumOptions={LanguageSimple}
                                    selectedId={
                                        isNaN(
                                            Number(
                                                urlParams['language'] === null || urlParams['language'] === ''
                                                    ? undefined
                                                    : urlParams['language']
                                            )
                                        )
                                            ? undefined
                                            : Number(urlParams['language'])
                                    }
                                    hasEmpty
                                    getOptionLabel={(key: string) => LanguageSimpleNames[key]}
                                />
                            )}
                        />
                    </Column>
                    <Column columnSize="30" className="p-right-space-3x top-space-3x">
                        <Controller
                            control={control}
                            name="studyType"
                            defaultValue={urlParams['studyType']}
                            render={({ field: { onChange } }) => (
                                <SelectByEnum
                                    label={t('studyType')}
                                    onChange={(id) => onChange(id)}
                                    enumOptions={StudyType}
                                    selectedId={Number(urlParams['studyType'])}
                                    hasEmpty
                                    getOptionLabel={(key: string) => StudyTypesNames[key]}
                                />
                            )}
                        />
                    </Column>
                    <Column columnSize="30" className="p-right-space-3x">
                        <Label labelText={t('isPartner')} labelFor="" />
                        <Controller
                            control={control}
                            name="isPartner"
                            defaultValue={urlParams['isPartner']}
                            render={({ field: { onChange } }) => (
                                <Select
                                    options={arrPartner}
                                    onChange={(v) => onChange(v[0]?.value)}
                                    values={[
                                        arrPartner.find((r) => r.value === `${urlParams['isPartner']}`) ??
                                            arrPartner[0],
                                    ]}
                                />
                            )}
                        />
                    </Column>
                    <Column columnSize="30" className="p-right-space-3x">
                        <Label labelText={t('quarter')} labelFor="" />
                        <Controller
                            control={control}
                            name="quarter"
                            defaultValue={urlParams['quarter']}
                            render={({ field: { onChange } }) => (
                                <Select
                                    options={arrQuarter}
                                    onChange={(v) => onChange(v[0]?.value)}
                                    values={[
                                        arrQuarter.find((r) => r.value === `${urlParams['quarter']}`) ?? arrQuarter[0],
                                    ]}
                                />
                            )}
                        />
                    </Column>
                    <Column columnSize="30">
                        <TextInput
                            type="number"
                            defaultValue={urlParams['year']}
                            label={t('year')}
                            id="yearId"
                            reg={register('year')}
                        />
                    </Column>
                    <Column columnSize="30">
                        <TextInput
                            type="number"
                            defaultValue={urlParams['durationDays']}
                            label={t('durationDays')}
                            id="durationDaysId"
                            reg={register('durationDays')}
                        />
                    </Column>
                    <Column columnSize="30">
                        <Controller
                            name="startDate"
                            defaultValue={urlParams['startDate']}
                            control={control}
                            render={({ field: { onChange } }) => (
                                <DatePricker
                                    canDelete
                                    canEmpty
                                    useZeroTime
                                    name="startDate"
                                    label={t('startDate')}
                                    returnValue="end"
                                    value={getValues('startDate')}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Column>
                    <Column columnSize="30">
                        <Controller
                            name="finishDate"
                            defaultValue={urlParams['finishDate']}
                            control={control}
                            render={({ field: { onChange } }) => (
                                <DatePricker
                                    canDelete
                                    canEmpty
                                    useZeroTime
                                    name="finishDate"
                                    label={t('finishDate')}
                                    returnValue="end"
                                    value={getValues('finishDate')}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Column>
                    <Column columnSize="100">
                        <div className="info-buttons d-flex">
                            <Button type="submit" buttonClass="primary" isDisabled={isSubmitting}>
                                {t('search')}
                            </Button>

                            <Button buttonClass="primary" handleClick={clear}>
                                {t('clear')}
                            </Button>
                        </div>
                    </Column>
                </form>
                {certificates && certificates.count !== 0 ? (
                    <Column columnSize="100" className="top-space-10x">
                        <GridFixed
                            columns={getColumns(t, !studyCenterId)}
                            rows={getGridData()}
                            pagination={{
                                total: certificates.count,
                                page: urlPage,
                                size: urlSize,
                                handleChange: setPageSize,
                            }}
                        />
                    </Column>
                ) : (
                    <EmptyListWarning />
                )}
            </Container>
        </>
    );
};

export default SimpleCertificateList;

const getColumns = (t: (v: string) => string, canEdit: boolean) => {
    const simpleColumns = {
        id: {
            value: 'Id',
            columnSize: '200',
        },
        courseCodeAndName: {
            value: t('courseCodeAndName'),
            columnSize: '150',
        },
        startDate: {
            value: t('startDate'),
            columnSize: '100',
        },
        finishDate: {
            value: t('finishDate'),
            columnSize: '100',
        },
        duration: {
            value: t('durationDays'),
            columnSize: '100',
        },
        quarter: {
            value: t('quarter'),
            columnSize: '100',
        },
        firstName: {
            value: t('name'),
            columnSize: '100',
        },
        lastName: {
            value: t('lastName'),
            columnSize: '100',
        },
        middleName: {
            value: t('middleName'),
            columnSize: '100',
        },
        email: {
            value: t('email'),
            columnSize: '100',
        },
        coach: {
            value: t('coach'),
            columnSize: '100',
        },
        studyType: {
            value: t('studyType'),
            columnSize: '100',
        },
        countryCity: {
            value: t('countryCity'),
            columnSize: '100',
        },
        company: {
            value: t('company'),
            columnSize: '100',
        },
        isPartner: {
            value: t('isPartner'),
            columnSize: '100',
        },
        creationDate: {
            value: t('creation-date'),
            columnSize: '150',
        },
        editDate: {
            value: t('editDate'),
            columnSize: '150',
        },
        downloadActions: {
            value: '',
            columnSize: '100',
        },
        /*deleteActions: {
            value: '',
            columnSize: '10',
        },*/
    };

    if (!canEdit) return simpleColumns;

    return {
        ...simpleColumns,
        updateActions: {
            value: '',
            columnSize: '10',
        },
    };
};
