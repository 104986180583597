import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserTestStatistic } from '../../models/test/user-test-statistic';
import { Icon } from '../Icon';
import QuestionAnswer from './QuestionAnswer';
import DOMPurify from 'isomorphic-dompurify';
import { Link } from 'components/Link';
import { Button } from '../Button';
import { UpdateTestStatistic } from '../../models/test/update-test-statistic';

import './TestAnswersStatistic.scss';
import EditAnswerStatistic from './EditAnswerStatistic';

interface TestStatisticProps {
    showDownload: boolean;
    showEdit: boolean;
    statistic: UserTestStatistic;
    updateStatistic?: (newVal: UpdateTestStatistic, userId: string) => void;
}

const TestAnswersStatistic: FC<TestStatisticProps> = ({ statistic, showDownload, showEdit, updateStatistic }) => {
    const {
        isDeleted,
        testName,
        isFail,
        isPercent,
        totalQuestions,
        evaluationScore,
        evaluationScorePercent,
        minimumSuccessScore,
        questions,
        passingTestId,
        userId,
    } = statistic;

    const { t } = useTranslation('user-test');

    const [isEditEnable, setIsEditEnable] = useState(false);

    const copyEditData = () => ({
        ...statistic,
        questions: statistic.questions.map((question) => ({
            ...question,
            options: question.options.map((opt) => ({ ...opt })),
        })),
    });

    const [editData, setEditData] = useState<UserTestStatistic>(copyEditData());

    const changeEditMode = () => {
        const nextVal = !isEditEnable;

        if (nextVal) setEditData(copyEditData());

        setIsEditEnable(nextVal);
    };

    const showTrueEdit = showEdit && !isDeleted;

    const changeSelect = (optionId: number, val: boolean) => {
        const newVal = {
            ...editData,
            questions: editData.questions.map((question) => ({
                ...question,
                options: question.options.map((opt) => {
                    if (opt.id === optionId)
                        return {
                            ...opt,
                            isSelected: val,
                        };

                    return { ...opt };
                }),
            })),
        };
        setEditData(newVal);
    };

    const validateAnswers = (): string => {
        const questionFail = editData.questions.find(
            (question) => question.options.filter((opt) => opt.isSelected).length !== question.rightCount
        );
        if (!questionFail) return '';

        return `qst_${questionFail.id}`;
    };

    const scrollToId = (id: string) => {
        const modal = document.querySelector('.ReactModal__Content');
        const el = document.querySelector(`#${id}`);

        if (!el || !modal) return;

        modal.scrollTo(0, modal.scrollTop + el.getBoundingClientRect().top - 50);
    };

    const save = () => {
        if (!updateStatistic) return;

        const validateResult = validateAnswers();
        if (validateResult) {
            scrollToId(validateResult);
            return;
        }

        setIsEditEnable(!isEditEnable);
        const result: UpdateTestStatistic = {
            passingId: editData.passingTestId,
            questions: editData.questions.map((question) => ({
                questionId: question.id,
                options: question.options
                    .filter((opt) => opt.isSelected)
                    .map((opt) => ({
                        optionId: opt.id,
                    })),
            })),
        };
        updateStatistic(result, userId);
    };

    return (
        <div className={`test-answers-statistic test-answers-statistic--${isFail ? 'fail' : 'success'}`}>
            <div className="test-answers-statistic__name">{testName}</div>
            <div className="test-answers-statistic__common-info">
                <div className="test-answers-statistic__icon">
                    <Icon iconName={isFail ? 'close' : 'check-mark-2'} />
                </div>
                <div>
                    <div className="test-answers-statistic__common-info-name">{t('total-questions')}</div>
                    <div className="test-answers-statistic__common-info-value">
                        {evaluationScore} / {totalQuestions}
                    </div>
                </div>
                <div>
                    <div className="test-answers-statistic__common-info-name">{t('evaluation-score')}</div>
                    <div className="test-answers-statistic__common-info-value">{evaluationScorePercent}%</div>
                </div>
                <div>
                    <div className="test-answers-statistic__common-info-name">{t('passing-score')}</div>
                    <div className="test-answers-statistic__common-info-value">
                        {minimumSuccessScore}
                        {isPercent && '%'}
                    </div>
                </div>
            </div>

            <div className="test-answers-statistic__questions">
                {questions.map((question, index) => (
                    <div key={question.id} className="test-answers-statistic-question">
                        <div className="test-answers-statistic__question-title">
                            {index + 1}.
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(question.text),
                                }}
                            />
                        </div>
                        {!isEditEnable ? (
                            question.options.map((option) => (
                                <QuestionAnswer fromUsers={!showDownload && !showEdit} key={option.id} {...option} />
                            ))
                        ) : (
                            <EditAnswerStatistic
                                questionId={editData.questions[index].id}
                                maxAnswers={editData.questions[index].rightCount}
                                options={editData.questions[index].options}
                                change={changeSelect}
                            />
                        )}
                    </div>
                ))}
            </div>
            {showDownload && !isEditEnable && (
                <Link
                    additionalClass="padding-bottom-10"
                    linkHref={`/api/Test/ResultPdf/${passingTestId}/${userId}`}
                    target="_blank"
                >
                    Download
                </Link>
            )}
            {showTrueEdit && !isEditEnable && (
                <Button handleClick={changeEditMode} pSize={'small'} buttonClass={'secondary'}>
                    Edit
                </Button>
            )}
            {isEditEnable && (
                <Button handleClick={save} pSize={'small'} buttonClass={'secondary'}>
                    Save
                </Button>
            )}
            {isEditEnable && (
                <Button handleClick={() => setIsEditEnable(!isEditEnable)} pSize={'small'} buttonClass={'secondary'}>
                    Close
                </Button>
            )}
        </div>
    );
};

export default TestAnswersStatistic;
