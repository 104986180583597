import React, { useEffect, useState } from 'react';
import { useQuery } from 'hooks';
import { useDebouncedEffect } from 'hooks/useDebouncedEffect/useDebouncedEffect';
import { ResponsePagination } from 'models/response-pagination';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Button,
    Column,
    Container,
    EmptyListWarning,
    GridFixed,
    Icon,
    Loader,
    Row,
    TextInput,
    Title,
} from 'components';
import moment from 'moment';
import { dateTimeFormat } from 'constants/date-format';
import { DEFAULT_PAGE_SIZE } from 'constants/default-page-size';
import { CoachModel } from 'models/simple-certificates/coach.model';
import { AdminCoachService } from 'services/admin-coach.service';

const sizeUrlParam = 'size';
const pageUrlParam = 'page';
const queryUrlParam = 'query';

const CoachesListPage = () => {
    const queryParams = useQuery();

    const { push, replace } = useHistory();
    const { pathname } = useLocation();

    const urlSize = +(queryParams.get(sizeUrlParam) as string);
    const urlPage = +(queryParams.get(pageUrlParam) as string);
    const urlQuery = queryParams.get(queryUrlParam) || '';
    const [loading, setLoading] = useState(false);
    const [coachName, setCoachName] = useState('');
    const [coaches, setCoaches] = useState<ResponsePagination<CoachModel>>({
        count: 0,
        items: [],
    });

    const getCoaches = () => {
        if (urlSize === 0) return;
        setLoading(true);
        AdminCoachService.getCoaches({
            page: urlPage,
            size: urlSize,
            name: urlQuery,
        })
            .then((d) => {
                setCoaches(d.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useDebouncedEffect(
        () => {
            queryParams.set(pageUrlParam, `0`);
            queryParams.set(sizeUrlParam, `${DEFAULT_PAGE_SIZE}`);
            queryParams.set(queryUrlParam, coachName);
            replace({
                search: queryParams.toString(),
                pathname,
            });
        },
        [coachName],
        400
    );

    useEffect(() => {
        getCoaches();
    }, [urlSize, urlPage, urlQuery]);

    const getExcel = () => {
        AdminCoachService.getCoachesXls({
            name: urlQuery,
        });
    };

    const deleteHandle = (id: string) => {
        if (window.confirm('Вы уверены?')) {
            setLoading(true);
            AdminCoachService.delete(id)
                .then(() => getCoaches())
                .finally(() => setLoading(true));
        }
    };

    const setPageSize = (page: number, size: number) => {
        queryParams.set(pageUrlParam, `${page}`);
        queryParams.set(sizeUrlParam, `${size}`);
        replace({
            search: queryParams.toString(),
            pathname,
        });
    };

    const getGridData = (): Row[] =>
        coaches.items.map((item) => ({
            ...item,
            creationDate: moment(item.creationDate).format(dateTimeFormat),
            studyCenter: item?.studyCenter?.name ?? '',
            updateActions: {
                additionalComponent: (
                    <Button pSize="none" handleClick={() => push(`${pathname}/edit/${item.id}`)}>
                        <Icon iconName="edit" />
                    </Button>
                ),
            },
            deleteActions: {
                additionalComponent: (
                    <Button pSize="none" handleClick={() => deleteHandle(item.id)}>
                        <Icon iconName="delete" />
                    </Button>
                ),
            },
        }));

    return (
        <>
            {loading && <Loader isBlock />}
            <Container>
                <Column columnSize="50">
                    <Title titleText="Тренеры" />
                </Column>
                <Column columnSize="100">
                    <TextInput
                        id="search-field"
                        label="Имя или фамилия"
                        value={coachName}
                        onChangeHandler={(e) => setCoachName(e.target.value)}
                    />
                </Column>
                <Column columnSize="100" className="text-right">
                    <Button buttonClass="primary" handleClick={() => push(`${pathname}/add`)}>
                        Добавить
                    </Button>
                    {coaches && coaches.count !== 0 && (
                        <Button buttonClass="primary" handleClick={getExcel}>
                            Excel
                        </Button>
                    )}
                </Column>
                {coaches && coaches.count !== 0 ? (
                    <Column columnSize="100" className="top-space-10x">
                        <GridFixed
                            columns={getColumns()}
                            rows={getGridData()}
                            pagination={{
                                total: coaches.count,
                                page: urlPage,
                                size: urlSize,
                                handleChange: setPageSize,
                            }}
                        />
                    </Column>
                ) : (
                    <EmptyListWarning />
                )}
            </Container>
        </>
    );
};

export default CoachesListPage;

const getColumns = () => ({
    id: {
        value: 'Id',
        columnSize: '200',
    },
    firstName: {
        value: 'Имя',
        columnSize: '200',
    },
    lastName: {
        value: 'Фамилия',
        columnSize: '200',
    },
    studyCenter: {
        value: 'Учебный центр',
        columnSize: '200',
    },
    creationDate: {
        value: 'Дата создания',
        columnSize: '200',
    },
    updateActions: {
        value: '',
        columnSize: '10',
    },
    deleteActions: {
        value: '',
        columnSize: '10',
    },
});
