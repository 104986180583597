import React, { useCallback, useEffect, useState } from 'react';
import { Button, Column, Container, TextInput, Title } from 'components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Controller, FieldValues, useForm } from 'react-hook-form-new';
import { MIN_TEXT_INPUT_LENGTH } from 'global-constants';
import { useTranslation } from 'react-i18next';
import { AddOrUpdateCoachModel } from 'models/simple-certificates/add-or-update-coach.model';
import { AdminCoachService } from 'services/admin-coach.service';
import { AutoCompliteForm } from 'components/AutoComplite/AutoCompliteForm';
import { StudyCenterListItem } from 'pages/study-centers/store/store';
import StudyCenterService from 'services/study-center.service';

const CoachesAddEditPage = () => {
    const [t] = useTranslation(['validation', 'admin-validation']);
    const { push } = useHistory();
    const { pathname } = useLocation();
    const { id } = useParams<{ id: string }>();

    const [loading, setLoading] = useState(false);
    const [studyCenters, setStudyCenters] = useState<StudyCenterListItem[]>([]);
    const [selectedStudyCenter, setSelectedStudyCenter] = useState<StudyCenterListItem>();

    const goBack = useCallback(() => {
        push(pathname.replace(/(\/edit\/.*|\/add)$/, ''));
    }, [push, pathname]);

    const methods = useForm<AddOrUpdateCoachModel>();
    const {
        register,
        formState: { isSubmitting, errors },
        handleSubmit,
        reset,
        control,
    } = methods;

    const submit = (fields: FieldValues) => {
        id
            ? AdminCoachService.update(fields as AddOrUpdateCoachModel).finally(goBack)
            : AdminCoachService.add(fields as AddOrUpdateCoachModel).finally(goBack);
    };

    useEffect(() => {
        if (!id) return;
        setLoading(true);
        AdminCoachService.get(id)
            .then((response) => {
                setSelectedStudyCenter(response.data?.studyCenter);
                reset(response.data);
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        setLoading(true);
        StudyCenterService.getList()
            .then((response) => {
                setStudyCenters(response?.data ?? []);
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <Container>
            <Column columnSize="50">
                <Title titleText={id ? 'Редактирование' : 'Добавление'} />
            </Column>

            {!loading && (
                <Column columnSize="100">
                    <form onSubmit={handleSubmit(submit)} autoComplete="off">
                        <input type="hidden" {...register('id')} defaultValue={id} />
                        <Column columnSize="100">
                            <TextInput
                                label="Имя"
                                id="nameId"
                                reg={register('firstName', {
                                    required: {
                                        value: true,
                                        message: t('validation:required-field'),
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT_LENGTH,
                                        message: t('validation:min-length', {
                                            minLength: MIN_TEXT_INPUT_LENGTH,
                                        }),
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: t('validation:max-length', {
                                            maxLength: 255,
                                        }),
                                    },
                                })}
                                errorMessage={errors.firstName?.message as string}
                            />
                        </Column>
                        <Column columnSize="100">
                            <TextInput
                                label="Фамилия"
                                id="lastNameId"
                                reg={register('lastName', {
                                    required: {
                                        value: true,
                                        message: t('validation:required-field'),
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT_LENGTH,
                                        message: t('validation:min-length', {
                                            minLength: MIN_TEXT_INPUT_LENGTH,
                                        }),
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: t('validation:max-length', {
                                            maxLength: 255,
                                        }),
                                    },
                                })}
                                errorMessage={errors.lastName?.message as string}
                            />
                        </Column>
                        <Column columnSize="50">
                            <Controller
                                name="studyCenterId"
                                defaultValue={selectedStudyCenter?.id}
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <AutoCompliteForm
                                        label="Выберите учебный центр"
                                        name="studyCenterId"
                                        id={'studyCenterId'}
                                        placeholder="Не выбрано"
                                        onChange={(v) => {
                                            onChange(v?.id);
                                        }}
                                        values={{
                                            id: selectedStudyCenter?.id ?? '',
                                            name: selectedStudyCenter?.name ?? '',
                                        }}
                                        dataArr={studyCenters.map((r) => ({ id: r.id, name: r.name }))}
                                        errorMessage={t(errors.studyCenterId?.message as string)}
                                    />
                                )}
                            />
                        </Column>
                        <Column columnSize="100">
                            <div className="info-buttons d-flex">
                                <Button type="submit" buttonClass="primary" isDisabled={isSubmitting}>
                                    Сохранить
                                </Button>

                                <Button buttonClass="primary" handleClick={goBack}>
                                    Отмена
                                </Button>
                            </div>
                        </Column>
                    </form>
                </Column>
            )}
        </Container>
    );
};

export default CoachesAddEditPage;
