import React, { FC } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { UserTestStatisticQuestionOption } from '../../models/test/user-test-statistic';
import { CheckboxNew } from '../CheckboxNew';

interface Props {
    questionId: number;
    maxAnswers?: number;
    options: UserTestStatisticQuestionOption[];
    change: (optionId: number, val: boolean) => void;
}

const EditAnswerStatistic: FC<Props> = ({ options, change, maxAnswers, questionId }) => {
    const error = !maxAnswers ? false : options.filter((r) => r.isSelected).length !== maxAnswers;
    const getClassName = (isRight: boolean) => (isRight ? 'right_checkbox' : 'error_checkbox');
    return (
        <div className={`statistic-question-answer_edit ${error ? 'error' : ''}`} id={`qst_${questionId}`}>
            {options &&
                options.map((opt) => (
                    <CheckboxNew
                        additionalClass={getClassName(opt.isRightOption)}
                        id={`opt_${opt.id}`}
                        isChecked={opt.isSelected}
                        handleChange={() => change(opt.id, !opt.isSelected)}
                        key={opt.id}
                        dangerHtml={DOMPurify.sanitize(opt.text)}
                    />
                ))}
            {error && <p className="error_notification">Количество ответов должно быть {maxAnswers}</p>}
        </div>
    );
};

export default EditAnswerStatistic;
