import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

import Error404 from 'pages/error-404/Error404';
import CoachesList from './CoachesList';
import CoachesAddEdit from './CoachesAddEdit';
import { useTitle } from 'utils';

const CoachesPage = () => {
    const { path } = useRouteMatch();

    useTitle('Тренеры');

    return (
        <Switch>
            <Route exact path={path}>
                <CoachesList />
            </Route>
            <Route exact path={`${path}/add`}>
                <CoachesAddEdit />
            </Route>
            <Route exact path={`${path}/edit/:id`}>
                <CoachesAddEdit />
            </Route>
            <Route path={`${path}/*`} component={Error404} />
        </Switch>
    );
};

export default CoachesPage;
