import React, { FC } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

import Error404 from 'pages/error-404/Error404';
import SimpleCertificateList from './SimpleCertificateList';
import SimpleCertificateAddEdit from './SimpleCertificateAddEdit';
import { useTitle } from 'utils';

interface IProps {
    studyCenterId?: number;
}

const SimpleCertificatesPage: FC<IProps> = ({ studyCenterId }) => {
    const { path } = useRouteMatch();

    useTitle('Сертификаты');

    return (
        <Switch>
            <Route exact path={path}>
                <SimpleCertificateList studyCenterId={studyCenterId} />
            </Route>
            <Route exact path={`${path}/add`}>
                <SimpleCertificateAddEdit studyCenterId={studyCenterId} />
            </Route>
            {!studyCenterId && (
                <Route exact path={`${path}/edit/:id`}>
                    <SimpleCertificateAddEdit studyCenterId={studyCenterId} />
                </Route>
            )}
            <Route path={`${path}/*`} component={Error404} />
        </Switch>
    );
};

export default SimpleCertificatesPage;
