import React, { FC } from 'react';
import { Label, ValidationMessage } from 'components/Form';
import Select from 'react-dropdown-select';

export interface Item {
    id: string;
    name: string;
}

interface Props {
    isDisabled?: boolean;
    arrAsync: Item[];
    onChange: (val?: Item[]) => void;
    isRequired?: boolean;
    label?: string;
    id: string;
    name: string;
    additionalClasses?: string;
    labelField: string;
    errorMessage?: string;
    isMulty?: boolean;
    values: Item[];
    valueField: string;
}

export const AutoCompliteManySync: FC<Props> = ({
    isDisabled,
    onChange,
    arrAsync,
    isRequired,
    label,
    name,
    id,
    additionalClasses,
    labelField,
    errorMessage,
    isMulty,
    valueField,
    values,
}) => {
    const withError = errorMessage ? 'with-error' : '';
    const classes = `${additionalClasses} ${withError} dropdown-select multy-select`;

    return (
        <>
            {label && <Label labelText={label} labelFor="" />}
            <Select
                multi={isMulty}
                className={classes}
                disabled={isDisabled}
                key={id}
                name={name}
                options={arrAsync}
                values={values}
                onChange={onChange}
                required={isRequired}
                labelField={labelField}
                valueField={valueField}
                searchable
                clearable
            />
            {errorMessage && <ValidationMessage>{errorMessage}</ValidationMessage>}
        </>
    );
};
