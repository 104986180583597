import api from '../api';
import { ResponsePagination } from '../models/response-pagination';
import { DownloadFileService } from './download-file.service';
import { GetSimpleCertificateModel } from 'models/simple-certificates/get-simple-certificate.model';
import { SimpleCertificateModel } from 'models/simple-certificates/simple-certificate.model';
import { GetSimpleCertificateExcelModel } from 'models/simple-certificates/get-simple-certificate-excel.model';
import { AddOrUpdateSimpleCertificateModel } from 'models/simple-certificates/add-or-update-simple-certificate.model';

export class AdminSimpleCertificateService {
    static getCertificates = (request: GetSimpleCertificateModel) =>
        api.get<ResponsePagination<SimpleCertificateModel>>(`AdminSimpleCertificate`, {
            params: request,
        });

    static getCertificatesXls = (request: GetSimpleCertificateExcelModel) =>
        DownloadFileService.download('AdminSimpleCertificate/xls', request);

    static add = (request: AddOrUpdateSimpleCertificateModel) =>
        api.post<SimpleCertificateModel>('AdminSimpleCertificate', request);
    static update = (request: AddOrUpdateSimpleCertificateModel) =>
        api.put<SimpleCertificateModel>('AdminSimpleCertificate', request);
    static delete = (id: string) => api.delete(`AdminSimpleCertificate/${id}`);
    static get = (id: string) => api.get<SimpleCertificateModel>(`AdminSimpleCertificate/${id}`);
}
