import React, { useCallback, useEffect, useState } from 'react';
import { Button, Column, Container, TextInput, Title } from 'components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Controller, FieldValues, useForm } from 'react-hook-form-new';
import { AddOrUpdateCourseModel } from 'models/simple-certificates/add-or-update-course.model';
import { MIN_TEXT_INPUT_LENGTH } from 'global-constants';
import { useTranslation } from 'react-i18next';
import { AdminCourseService } from 'services/admin-course.service';
import { AutoCompliteMany } from 'components/AutoComplite/AutoCompliteMany';
import { AdminCoachService } from 'services/admin-coach.service';
import { CoachModel } from 'models/simple-certificates/coach.model';

const CoursesAddEditPage = () => {
    const [t] = useTranslation(['validation']);
    const { push } = useHistory();
    const { pathname } = useLocation();
    const { id } = useParams<{ id: string }>();

    const [loading, setLoading] = useState(false);

    const goBack = useCallback(() => {
        push(pathname.replace(/(\/edit\/.*|\/add)$/, ''));
    }, [push, pathname]);

    const [selectedCoaches, setSelectedCoaches] = useState<CoachModel[]>([]);
    const methods = useForm<AddOrUpdateCourseModel>();
    const {
        register,
        formState: { isSubmitting, errors },
        handleSubmit,
        reset,
        control,
    } = methods;

    const submit = (fields: FieldValues) => {
        id
            ? AdminCourseService.update(fields as AddOrUpdateCourseModel).finally(goBack)
            : AdminCourseService.add(fields as AddOrUpdateCourseModel).finally(goBack);
    };

    useEffect(() => {
        if (!id) return;
        setLoading(true);
        AdminCourseService.get(id)
            .then((response) => {
                setSelectedCoaches(response?.data?.coaches ?? []);
                reset(response.data);
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <Container>
            <Column columnSize="50">
                <Title titleText={id ? 'Редактирование' : 'Добавление'} />
            </Column>

            {!loading && (
                <Column columnSize="100">
                    <form onSubmit={handleSubmit(submit)} autoComplete="off">
                        <input type="hidden" {...register('id')} defaultValue={id} />
                        <Column columnSize="100">
                            <TextInput
                                label="Код курса"
                                id="codeId"
                                reg={register('code', {
                                    required: {
                                        value: true,
                                        message: t('validation:required-field'),
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT_LENGTH,
                                        message: t('validation:min-length', {
                                            minLength: MIN_TEXT_INPUT_LENGTH,
                                        }),
                                    },
                                    maxLength: {
                                        value: 50,
                                        message: t('validation:max-length', {
                                            maxLength: 50,
                                        }),
                                    },
                                    pattern: {
                                        value: /^[\w\d\s\\.\\:а-яА-Я]*$/,
                                        message: t('validation:only-string-and-digit'),
                                    },
                                })}
                                errorMessage={errors.code?.message as string}
                            />
                        </Column>
                        <Column columnSize="100">
                            <TextInput
                                label="Название курса"
                                id="nameId"
                                reg={register('name', {
                                    required: {
                                        value: true,
                                        message: t('validation:required-field'),
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT_LENGTH,
                                        message: t('validation:min-length', {
                                            minLength: MIN_TEXT_INPUT_LENGTH,
                                        }),
                                    },
                                    maxLength: {
                                        value: 250,
                                        message: t('validation:max-length', {
                                            maxLength: 250,
                                        }),
                                    },
                                    pattern: {
                                        value: /^[\w\d\s\\.\\:а-яА-Я]*$/,
                                        message: t('validation:only-string-and-digit'),
                                    },
                                })}
                                errorMessage={errors.name?.message as string}
                            />
                        </Column>
                        <Column columnSize="100">
                            <Controller
                                name="coachesId"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <AutoCompliteMany
                                        name="coachesId"
                                        additionalClasses="form-field"
                                        label="Тренеры"
                                        id="coachesId"
                                        labelField="name"
                                        valueField="id"
                                        values={selectedCoaches.map((r) => ({
                                            id: r.id,
                                            name: `${r.lastName} ${r.firstName}`,
                                        }))}
                                        onChange={(e) => onChange(e?.map((r) => r.id))}
                                        isMulty
                                        getArrAsync={(s?: string) =>
                                            AdminCoachService.getCoaches({ page: 0, size: 20, name: s }).then((d) =>
                                                d.data.items.map((m) => ({
                                                    id: m.id,
                                                    name:
                                                        `${m.lastName} ${m.firstName}` +
                                                        (m.studyCenter ? `(${m.studyCenter?.name})` : ''),
                                                }))
                                            )
                                        }
                                    />
                                )}
                            />
                        </Column>
                        <Column columnSize="100">
                            <div className="info-buttons d-flex">
                                <Button type="submit" buttonClass="primary" isDisabled={isSubmitting}>
                                    Сохранить
                                </Button>

                                <Button buttonClass="primary" handleClick={goBack}>
                                    Отмена
                                </Button>
                            </div>
                        </Column>
                    </form>
                </Column>
            )}
        </Container>
    );
};

export default CoursesAddEditPage;
