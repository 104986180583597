import api from '../api';
import { ResponsePagination } from '../models/response-pagination';
import { DownloadFileService } from './download-file.service';
import { GetCoachModel } from 'models/simple-certificates/get-coach.model';
import { CoachModel } from 'models/simple-certificates/coach.model';
import { GetCoachExcelModel } from 'models/simple-certificates/get-coach-excel.model';
import { AddOrUpdateCoachModel } from 'models/simple-certificates/add-or-update-coach.model';

export class AdminCoachService {
    static getCoaches = (request: GetCoachModel) =>
        api.get<ResponsePagination<CoachModel>>(`AdminCoach`, {
            params: request,
        });

    static getCoachesXls = (request: GetCoachExcelModel) => DownloadFileService.download('AdminCoach/xls', request);

    static add = (request: AddOrUpdateCoachModel) => api.post<CoachModel>('AdminCoach', request);
    static update = (request: AddOrUpdateCoachModel) => api.put<CoachModel>('AdminCoach', request);
    static delete = (id: string) => api.delete(`AdminCoach/${id}`);
    static get = (id: string) => api.get<CoachModel>(`AdminCoach/${id}`);
}
