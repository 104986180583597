import { GetCourseExcelModel } from 'models/simple-certificates/get-course-excel.model';
import api from '../api';
import { ResponsePagination } from '../models/response-pagination';
import { DownloadFileService } from './download-file.service';
import { GetCourseModel } from 'models/simple-certificates/get-course.model';
import { CourseModel } from 'models/simple-certificates/course.model';
import { AddOrUpdateCourseModel } from 'models/simple-certificates/add-or-update-course.model';

export class AdminCourseService {
    static getCourses = (request: GetCourseModel) =>
        api.get<ResponsePagination<CourseModel>>(`AdminCourse`, {
            params: request,
        });

    static getCoursesXls = (request: GetCourseExcelModel) => DownloadFileService.download('AdminCourse/xls', request);

    static add = (request: AddOrUpdateCourseModel) => api.post<CourseModel>('AdminCourse', request);
    static update = (request: AddOrUpdateCourseModel) => api.put<CourseModel>('AdminCourse', request);
    static delete = (id: string) => api.delete(`AdminCourse/${id}`);
    static get = (id: string) => api.get<CourseModel>(`AdminCourse/${id}`);
    static getByCoaches = (ids: string[]) => {
        if (!ids || !ids?.length) return Promise.resolve({ data: [] as CourseModel[] });

        return api.get<CourseModel[]>(`AdminCourse/ByCoachesId?ids=${ids.join('&ids=').replace(/ids=$/, '')}`);
    };
}
