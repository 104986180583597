import api from '../api';
import { ResponsePagination } from '../models/response-pagination';
import { StudyGroupListItem } from '../models/study-group/study-group-list-item';
import { StudyCenterListItem } from '../pages/study-centers/store/store';
import { StudyCenterFormData } from '../pages/study-centers/study-center-form/StudyCenterForm';
import { StudyCenter } from '../models/study-center';

import { StudyCenterUser } from '../models/study-center-users/study-center-user';
import { CoachModel } from 'models/simple-certificates/coach.model';

export default class StudyCenterService {
    static getList = (query?: string) => api.get<StudyCenterListItem[]>(`StudyCenter/Get/${query || ''}`);

    static getById = (id: number) => api.get<StudyCenter>(`StudyCenter/GetById/${id}`);

    static add = (model: StudyCenterFormData) => api.post<StudyCenter>('StudyCenter', model);

    static update = (model: StudyCenter) => api.put<StudyCenter>('StudyCenter', model);

    static getStudyGroupsById = (id: number) =>
        api.get<StudyGroupListItem[]>(`AdminStudyCenter/GetTrainingGroupsByStudyCenterId/${id}`);

    static getStudents = (p: { studyCenterId: number; email?: string }) =>
        api.get<ResponsePagination<StudyCenterUser>>(`AdminStudyCenter/GetStudentsOfStudyCenter`, {
            params: {
                ...p,
                includeBlocked: true,
            },
        });

    static deleteStudyGroupById = (id: number) =>
        api.delete<StudyGroupListItem[]>(`TrainingGroup/DeleteStudyCenterFromTrainingGroup/${id}`);

    static getTrainers = (centerId: number) => api.get<CoachModel[]>(`StudyCenter/GetTrainers/${centerId}`);
}
