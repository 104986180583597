import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Column, Container, DatePricker, Loader, Switch, TextInput, Title } from 'components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Controller, FieldValues, useForm } from 'react-hook-form-new';
import { MIN_TEXT_INPUT_LENGTH } from 'global-constants';
import { useTranslation } from 'react-i18next';
import { AddOrUpdateSimpleCertificateModel } from 'models/simple-certificates/add-or-update-simple-certificate.model';
import { AdminSimpleCertificateService } from 'services/admin-simple-sertificate.service';
import { StudyType, StudyTypesNames } from 'enums/study-type';
import { SimpleCertificateModel } from 'models/simple-certificates/simple-certificate.model';
import SelectByEnum from 'components/SelectByEnum/SelectByEnum';
import { LanguageSimple, LanguageSimpleNames } from 'enums/language-simple';
import { MIN_MONTH_ENABLE_CERTIFICATE } from 'pages/tests/test-form/constants';
import { getDateZeroTimeZoneISO } from 'utils/date';
import { emailRegex } from 'constants/regex';
import './SimpleCertificateStyles.scss';
import { StudyCenter } from 'models/study-center';
import StudyCenterService from 'services/study-center.service';
import { CoachModel } from 'models/simple-certificates/coach.model';
import { CourseModel } from 'models/simple-certificates/course.model';
import { AdminCourseService } from 'services/admin-course.service';
import { StudyCenterListItem } from 'pages/study-centers/store/store';
import { AutoCompliteForm } from 'components/AutoComplite/AutoCompliteForm';
import { AdminCoachService } from 'services/admin-coach.service';
import { AutoCompliteManySync } from 'components/AutoComplite/AutoCompliteManySync';

interface IProps {
    studyCenterId?: number;
}

const SimpleCertificateAddEdit: FC<IProps> = ({ studyCenterId }) => {
    const [t] = useTranslation(['validation', 'admin-validation', 'page-simple-certification']);
    const { push } = useHistory();
    const { pathname } = useLocation();
    const { id } = useParams<{ id: string }>();

    const [loading, setLoading] = useState(false);

    const goBack = useCallback(() => {
        push(pathname.replace(/(\/edit\/.*|\/add)$/, ''));
    }, [push, pathname]);

    const methods = useForm<AddOrUpdateSimpleCertificateModel>();
    const {
        register,
        formState: { isSubmitting, errors },
        handleSubmit,
        reset,
        watch,
        control,
        getValues,
        setValue,
    } = methods;

    const [savedItem, setSavedItem] = useState<SimpleCertificateModel>();
    const [allStudyCenters, setAllStudyCenters] = useState<StudyCenterListItem[]>([]);
    const [studyCenter, setStudyCenter] = useState<StudyCenter>();
    const [coaches, setCoaches] = useState<CoachModel[]>([]);
    const [courses, setCourses] = useState<CourseModel[]>([]);

    /*admin add*/
    useEffect(() => {
        if (studyCenterId || id) return;
        setLoading(true);
        StudyCenterService.getList()
            .then((response) => {
                setAllStudyCenters(response?.data ?? []);
            })
            .finally(() => setLoading(false));
    }, []);

    /*admin edit*/
    useEffect(() => {
        if (studyCenterId || !id) return;
        setLoading(true);
        StudyCenterService.getList()
            .then((response) => {
                setAllStudyCenters(response?.data ?? []);
                return AdminSimpleCertificateService.get(id);
            })
            .then((response) => {
                setSavedItem(response.data);
                reset(response.data);

                return AdminCoachService.getCoaches({
                    page: 0,
                    size: 0,
                    studyCenterId: response.data?.studyCenter?.id,
                });
            })
            .then((response) => {
                setCoaches(response.data?.items);
                return AdminCourseService.getByCoaches(response.data?.items?.map((m) => m.id) ?? []);
            })
            .then((response) => {
                setCourses(response.data ?? []);
            })
            .finally(() => setLoading(false));
    }, []);

    /*sc add*/
    useEffect(() => {
        if (!studyCenterId || id) return;
        setLoading(true);
        StudyCenterService.getById(studyCenterId)
            .then((response) => {
                setStudyCenter(response.data);
                return AdminCoachService.getCoaches({
                    page: 0,
                    size: 0,
                    studyCenterId: studyCenterId,
                });
            })
            .then((response) => {
                setCoaches(response.data?.items);
                return AdminCourseService.getByCoaches(response.data?.items?.map((m) => m.id) ?? []);
            })
            .then((response) => {
                setCourses(response.data ?? []);
            })
            .finally(() => setLoading(false));
    }, []);

    /*sc edit*/
    useEffect(() => {
        if (!studyCenterId || !id) return;
        setLoading(true);
        AdminSimpleCertificateService.get(id)
            .then((response) => {
                setSavedItem(response.data);
                reset(response.data);
                return StudyCenterService.getById(studyCenterId);
            })
            .then((response) => {
                setStudyCenter(response.data);
                return AdminSimpleCertificateService.get(id);
            })
            .then((response) => {
                setSavedItem(response.data);
                reset(response.data);

                return AdminCoachService.getCoaches({
                    page: 0,
                    size: 0,
                    studyCenterId: studyCenterId,
                });
            })
            .then((response) => {
                setCoaches(response.data?.items);
                return AdminCourseService.getByCoaches(response.data?.items?.map((m) => m.id) ?? []);
            })
            .then((response) => {
                setCourses(response.data ?? []);
            })
            .finally(() => setLoading(false));
    }, []);

    const submit = (fields: FieldValues) => {
        setLoading(true);
        id
            ? AdminSimpleCertificateService.update(fields as AddOrUpdateSimpleCertificateModel).finally(goBack)
            : AdminSimpleCertificateService.add(fields as AddOrUpdateSimpleCertificateModel).finally(goBack);
    };

    const studyFormatForm = watch('studyType')?.valueOf();
    const studyCenterForm = watch('studyCenterId')?.valueOf();
    const coachesForm = watch('coachesId')?.valueOf() as string[];

    useEffect(() => {
        if (studyCenterForm)
            AdminCoachService.getCoaches({ page: 0, size: 0, studyCenterId: studyCenterForm }).then((response) =>
                setCoaches(response.data.items)
            );
        else setCoaches([]);
    }, [studyCenterForm]);

    useEffect(() => {
        if (coachesForm && coachesForm.length)
            AdminCourseService.getByCoaches(coachesForm).then((response) => setCourses(response.data));
        else setCourses([]);
    }, [coachesForm]);

    return (
        <Container>
            <Column columnSize="50">
                <Title titleText={id ? t('page-simple-certification:edit') : t('page-simple-certification:create')} />
            </Column>

            {loading && <Loader isBlock />}

            {!loading && (
                <Column columnSize="100">
                    <form onSubmit={handleSubmit(submit)} autoComplete="off">
                        <input type="hidden" {...register('id')} defaultValue={id} />
                        <Column columnSize="100">
                            <TextInput
                                label={t('page-simple-certification:company')}
                                id="companyId"
                                reg={register('company', {
                                    required: {
                                        value: true,
                                        message: t('validation:required-field'),
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT_LENGTH,
                                        message: t('validation:min-length', {
                                            minLength: MIN_TEXT_INPUT_LENGTH,
                                        }),
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: t('validation:max-length', {
                                            maxLength: 255,
                                        }),
                                    },
                                })}
                                errorMessage={errors.company?.message as string}
                                disabled={!!id}
                            />
                        </Column>
                        <Column columnSize="100">
                            <TextInput
                                label={t('page-simple-certification:email')}
                                id="emailId"
                                reg={register('email', {
                                    required: {
                                        value: true,
                                        message: t('validation:required-field'),
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT_LENGTH,
                                        message: t('validation:min-length', {
                                            minLength: MIN_TEXT_INPUT_LENGTH,
                                        }),
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: t('validation:max-length', {
                                            maxLength: 255,
                                        }),
                                    },
                                    pattern: {
                                        value: emailRegex,
                                        message: t('validation:email-not-valid'),
                                    },
                                })}
                                errorMessage={errors.email?.message as string}
                            />
                        </Column>
                        <Column columnSize="100">
                            <TextInput
                                label={t('page-simple-certification:name')}
                                id="firstNameId"
                                reg={register('firstName', {
                                    required: {
                                        value: true,
                                        message: t('validation:required-field'),
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT_LENGTH,
                                        message: t('validation:min-length', {
                                            minLength: MIN_TEXT_INPUT_LENGTH,
                                        }),
                                    },
                                    maxLength: {
                                        value: 40,
                                        message: t('validation:max-length', {
                                            maxLength: 40,
                                        }),
                                    },
                                })}
                                errorMessage={errors.firstName?.message as string}
                            />
                        </Column>
                        <Column columnSize="100">
                            <TextInput
                                label={t('page-simple-certification:lastName')}
                                id="lastNameId"
                                reg={register('lastName', {
                                    required: {
                                        value: true,
                                        message: t('validation:required-field'),
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT_LENGTH,
                                        message: t('validation:min-length', {
                                            minLength: MIN_TEXT_INPUT_LENGTH,
                                        }),
                                    },
                                    maxLength: {
                                        value: 40,
                                        message: t('validation:max-length', {
                                            maxLength: 40,
                                        }),
                                    },
                                })}
                                errorMessage={errors.lastName?.message as string}
                            />
                        </Column>
                        <Column columnSize="100">
                            <TextInput
                                label={t('page-simple-certification:middleName')}
                                id="patronymicId"
                                reg={register('patronymic', {
                                    maxLength: {
                                        value: 40,
                                        message: t('validation:max-length', {
                                            maxLength: 40,
                                        }),
                                    },
                                })}
                                errorMessage={errors.patronymic?.message as string}
                            />
                        </Column>
                        <Column columnSize="100">
                            <Controller
                                control={control}
                                name="studyType"
                                defaultValue={savedItem?.studyType || StudyType.Online}
                                render={({ field: { onChange } }) => (
                                    <SelectByEnum
                                        label={t('page-simple-certification:studyType')}
                                        additionalClasses="form-field"
                                        onChange={(id) => onChange(id)}
                                        enumOptions={StudyType}
                                        selectedId={savedItem?.studyType || StudyType.Online}
                                        getOptionLabel={(key: string) => StudyTypesNames[key]}
                                        isDisabled={!!id}
                                    />
                                )}
                            />
                        </Column>
                        {studyFormatForm === StudyType.Offline && (
                            <Column columnSize="100">
                                <TextInput
                                    label={t('page-simple-certification:countryCity')}
                                    id="countryCityId"
                                    reg={register('countryCity', {
                                        required: {
                                            value: true,
                                            message: t('validation:required-field'),
                                        },
                                        minLength: {
                                            value: MIN_TEXT_INPUT_LENGTH,
                                            message: t('validation:min-length', {
                                                minLength: MIN_TEXT_INPUT_LENGTH,
                                            }),
                                        },
                                    })}
                                    errorMessage={errors.countryCity?.message as string}
                                    disabled={!!id}
                                />
                            </Column>
                        )}
                        <Column columnSize="100">
                            <Controller
                                control={control}
                                name="language"
                                defaultValue={savedItem?.language || LanguageSimple.En}
                                render={({ field: { onChange } }) => (
                                    <SelectByEnum
                                        label={t('page-simple-certification:language')}
                                        additionalClasses="form-field"
                                        onChange={(id) => onChange(id)}
                                        enumOptions={LanguageSimple}
                                        selectedId={savedItem?.language || LanguageSimple.En}
                                        getOptionLabel={(key: string) => LanguageSimpleNames[key]}
                                        isDisabled={!!id}
                                    />
                                )}
                            />
                        </Column>
                        <Column columnSize="100" className="simpleCertificateSwitch">
                            <Controller
                                name="isPartner"
                                defaultValue={savedItem?.isPartner || false}
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Switch
                                        label={t('page-simple-certification:isPartner')}
                                        id="isPartnerId"
                                        defaultChecked={savedItem?.isPartner || false}
                                        onChange={(e) => onChange(e)}
                                        disabled={!!id}
                                    />
                                )}
                            />
                        </Column>
                        <Column columnSize="100">
                            <TextInput
                                type="number"
                                defaultValue={savedItem?.countOfHours}
                                label={t('page-simple-certification:hoursCount')}
                                id="countOfHoursId"
                                reg={register('countOfHours', {
                                    min: {
                                        value: MIN_MONTH_ENABLE_CERTIFICATE,
                                        message: t('admin-validation:incorrect-input'),
                                    },
                                })}
                                errorMessage={errors.countOfHours?.message as string}
                            />
                        </Column>
                        <Column columnSize="100">
                            <Controller
                                name="startDate"
                                defaultValue={savedItem?.startDate || getDateZeroTimeZoneISO(new Date(), 'start')}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('admin-validation:required-select'),
                                    },
                                }}
                                render={() => (
                                    <DatePricker
                                        useZeroTime
                                        name="startDate"
                                        label={t('page-simple-certification:startDate')}
                                        returnValue="end"
                                        value={getValues('startDate')}
                                        onChange={(v) => setValue('startDate', v as string)}
                                    />
                                )}
                            />
                        </Column>
                        <Column columnSize="100">
                            <Controller
                                name="finishDate"
                                defaultValue={savedItem?.finishDate || getDateZeroTimeZoneISO(new Date(), 'start')}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('admin-validation:required-select'),
                                    },
                                }}
                                render={() => (
                                    <DatePricker
                                        useZeroTime
                                        name="finishDate"
                                        label={t('page-simple-certification:finishDate')}
                                        returnValue="end"
                                        value={getValues('finishDate')}
                                        onChange={(v) => setValue('finishDate', v as string)}
                                    />
                                )}
                            />
                        </Column>
                        <Column columnSize="50">
                            <Controller
                                name="studyCenterId"
                                control={control}
                                defaultValue={savedItem?.studyCenter?.id || studyCenterId}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('validation:required-field'),
                                    },
                                }}
                                render={({ field: { onChange } }) => (
                                    <AutoCompliteForm
                                        isDisabled={!!studyCenterId || !!id}
                                        label={t('page-simple-certification:selectStudyCenter')}
                                        name="studyCenterId"
                                        id={'studyCenterId'}
                                        placeholder={t('page-simple-certification:notSelected')}
                                        onChange={(v) => {
                                            onChange(v?.id);
                                        }}
                                        values={{
                                            id: savedItem?.studyCenter?.id ?? studyCenter?.id ?? '',
                                            name: savedItem?.studyCenter?.name ?? studyCenter?.name ?? '',
                                        }}
                                        dataArr={allStudyCenters.map((r) => ({ id: r.id, name: r.name }))}
                                        errorMessage={t(errors.studyCenterId?.message as string)}
                                    />
                                )}
                            />
                        </Column>
                        <Column columnSize="100" className="simpleCertificateSwitch">
                            <Controller
                                name="coachesId"
                                control={control}
                                defaultValue={savedItem?.coaches?.map((m) => m.id) || []}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('validation:required-field'),
                                    },
                                    validate: (val) =>
                                        val.length <= 3
                                            ? true
                                            : t('validation:max-count', {
                                                  count: 3,
                                              }),
                                }}
                                render={({ field: { onChange } }) => (
                                    <AutoCompliteManySync
                                        name="coachesId"
                                        additionalClasses="form-field"
                                        label={t('page-simple-certification:coach')}
                                        id="coachesId"
                                        labelField="name"
                                        valueField="id"
                                        values={
                                            savedItem?.coaches?.map((r) => ({
                                                id: r.id,
                                                name: `${r.lastName} ${r.firstName}`,
                                            })) ?? []
                                        }
                                        onChange={(e) => onChange(e?.map((r) => r.id))}
                                        isMulty
                                        arrAsync={coaches.map((m) => ({
                                            id: m.id,
                                            name: `${m.lastName} ${m.firstName}`,
                                        }))}
                                        errorMessage={t(errors.coachesId?.message as string)}
                                        isDisabled={!!id}
                                    />
                                )}
                            />
                        </Column>
                        <Column columnSize="50">
                            <Controller
                                name="courseId"
                                defaultValue={savedItem?.course?.id}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('validation:required-field'),
                                    },
                                }}
                                render={({ field: { onChange } }) => (
                                    <AutoCompliteForm
                                        label={t('page-simple-certification:selectCourse')}
                                        name="courseIdId"
                                        id={'courseIdId'}
                                        placeholder={t('page-simple-certification:notSelected')}
                                        onChange={(v) => {
                                            onChange(v?.id);
                                        }}
                                        values={{
                                            id: savedItem?.course?.id ?? '',
                                            name: savedItem?.course
                                                ? `${savedItem?.course?.code} ${savedItem?.course?.name}`
                                                : '',
                                        }}
                                        dataArr={courses.map((r) => ({ id: r.id, name: `${r.code} ${r.name}` }))}
                                        errorMessage={t(errors.courseId?.message as string)}
                                        isDisabled={!!id}
                                    />
                                )}
                            />
                        </Column>
                        <Column columnSize="100">
                            <div className="info-buttons d-flex">
                                <Button type="submit" buttonClass="primary" isDisabled={isSubmitting}>
                                    {t('page-simple-certification:save')}
                                </Button>

                                <Button buttonClass="primary" handleClick={goBack}>
                                    {t('page-simple-certification:cancel')}
                                </Button>
                            </div>
                        </Column>
                    </form>
                </Column>
            )}
        </Container>
    );
};

export default SimpleCertificateAddEdit;
