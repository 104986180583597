import React, { FC } from 'react';
import { Icon } from '../Icon';
import DOMPurify from 'isomorphic-dompurify';

import './QuestionAnswer.scss';

interface QuestionAnswerProps {
    isRightSelected: boolean;
    isSelected: boolean;
    isRightOption: boolean;
    fromUsers: boolean;
    text: string;
}

const QuestionAnswer: FC<QuestionAnswerProps> = ({ isRightSelected, isSelected, text, isRightOption, fromUsers }) => {
    const modifier = isSelected ? (isRightSelected ? 'right' : 'error') : '';
    const questionModifier = !fromUsers
        ? isRightOption
            ? 'right'
            : 'error'
        : fromUsers && isRightOption && modifier
        ? 'from-users-right'
        : modifier
        ? 'from-users-error'
        : '';

    return (
        <div className={'statistic-question-answer ' + `statistic-question-answer--${questionModifier}`}>
            <div className="statistic-question-answer__icon">
                {modifier && <Icon iconName={modifier === 'error' ? 'close' : 'check-mark-2'} />}
            </div>
            <div
                className="statistic-question-answer__text"
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(text),
                }}
            />
        </div>
    );
};

export default QuestionAnswer;
