import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '../Form';
import { Option, Select } from '../Select';

// only key - number enum
interface SelectDirectionProps {
    enumOptions: Record<string, string | number>;
    hasEmpty?: boolean;
    selectedId?: number;
    onChange: (id: number) => void;
    label?: string;
    additionalClasses?: string;
    isDisabled?: boolean;
    getOptionLabel?: (key: string) => string;
}

const SelectByEnum: FC<SelectDirectionProps> = (props) => {
    const { label, enumOptions, hasEmpty, selectedId, onChange, isDisabled, additionalClasses, getOptionLabel } = props;

    const [t] = useTranslation('info');
    const [options, setOptions] = useState<Option[]>([]);

    useEffect(() => {
        const newOptions: Option[] = Object.values(enumOptions)
            .filter((value): value is string => typeof value === 'string')
            .map((key) => ({
                value: enumOptions[key],
                label: getOptionLabel ? getOptionLabel(key) : key,
            }));

        if (hasEmpty) {
            newOptions.unshift({
                label: t('info:empty-select'),
                value: '',
            });
        }
        setOptions(newOptions);
    }, [enumOptions, hasEmpty, t]);

    const getValues = (): Option => {
        let result;

        if (selectedId !== undefined && selectedId !== null) {
            result = options.find((item) => item.value === selectedId);
        }

        return result || options[0];
    };

    if (!options.length) {
        return null;
    }

    return (
        <>
            {label && <Label labelText={t(label)} labelFor="" />}
            <Select
                additionalClasses={additionalClasses}
                isDisabled={isDisabled}
                values={[getValues()]}
                options={options}
                onChange={([select]) => onChange(select.value as number)}
            />
        </>
    );
};

export default SelectByEnum;
