import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

import Error404 from 'pages/error-404/Error404';
import CoursesList from './CoursesList';
import CoursesAddEdit from './CoursesAddEdit';
import { useTitle } from 'utils';

const CoursesPage = () => {
    const { path } = useRouteMatch();

    useTitle('Курсы');

    return (
        <Switch>
            <Route exact path={path}>
                <CoursesList />
            </Route>
            <Route exact path={`${path}/add`}>
                <CoursesAddEdit />
            </Route>
            <Route exact path={`${path}/edit/:id`}>
                <CoursesAddEdit />
            </Route>
            <Route path={`${path}/*`} component={Error404} />
        </Switch>
    );
};

export default CoursesPage;
